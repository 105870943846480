import React, { useState } from 'react';

import './CitiesSelect.scss';
import { findCityByName } from '../../../../services/Tools.service';

const CitiesSelect = ({ placeholder, value: inputValue, onChange }) => {
  const [citiesFound, setCitiesFound] = useState([]);
  const [showResults, setDisplay] = useState(false);
  const [searching, setSearching] = useState(false);
  const [value, setValue] = useState(inputValue || '');

  const onCitySelected = city => {
    setValue(`${city.cityName}, ${city.stateName}`);
    setDisplay(false);
    onChange(city);
  };

  const findCity = async cityName => {
    setValue(cityName);
    setDisplay(true);

    if (!cityName.length) {
      setDisplay(false);
      setCitiesFound([]);
    }

    if (cityName.length < 3) {
      setDisplay(false);
      return;
    }

    setSearching(true);

    const citiesFound = await findCityByName(cityName);
    setSearching(false);

    const firstNCities = citiesFound.data.slice(0, 5);

    setCitiesFound(firstNCities);
  };

  return (
    <>
      <input
        className="autocomplete-input-cities u-full-width"
        placeholder={placeholder}
        onClick={() => setDisplay(!showResults)}
        value={value}
        onChange={event => findCity(event.target.value)}
        autoComplete="off"
      />
      {showResults && (
        <ul className="cities-autocomplete-result">
          {citiesFound.map(city => {
            return (
              <li
                className="list-results-autocomplete"
                onClick={() => onCitySelected(city)}
                key={city.salesforceId}
              >
                <p className="citySelectedCustom">{` ${city.cityName} - ${city.stateName} `}</p>
              </li>
            );
          })}
        </ul>
      )}

      {searching && (
        <div className="cities-autocomplete-result">
          <p className="list-result-autocomplete u-full-width">Buscando ...</p>
        </div>
      )}
    </>
  );
};

export default CitiesSelect;
