export const DOCUMENT_TYPE_IDENTITY = [
  { code: '36', description: 'Cédula ciudadanía' },
  { code: '33', description: 'Cédula Extranjería' },
  { code: '44', description: 'Carné Diplomático' },
  { code: '40', description: 'Pasaporte' },
  { code: '34', description: 'Tarjeta de Identidad' },
  { code: '35', description: 'Registro Civil' }
];

export const CDA_CITIES = [
  {
    UUID: '501',
    CITY: 'MEDELLIN - SAN JOAQUIN',
    CDA: 'AJUSTEV SAN JOAQUIN',
    RED: 'AJUSTEV',
    TIPO_DE_VEHICULO: 'LIVIANOS - MOTOS',
    DIRECCION: 'CALLE 43 N° 69 - 34 LOCAL  203 BARRIO SAN JOAQUIN',
    LUNES_A_VIERNES: '8:00 AM - 12:00 PM 1:00 PM - 5:00 PM',
    SABADOS: '8:00 AM - 12:00 PM'
  },
  {
    UUID: '502',
    CITY: 'MEDELLIN - BARRIO COLOMBIA',
    CDA: 'AJUSTEV SAS',
    RED: 'AJUSTEV',
    TIPO_DE_VEHICULO: 'LIVIANOS - MOTOS - PESADOS',
    DIRECCION: 'Calle 31 Nª 43ª-72 BARRIO COLOMBIA',
    LUNES_A_VIERNES: '8:00 AM - 5:00 PM',
    SABADOS: '8:00 AM - 12:00 PM'
  },
  {
    UUID: '503',
    CITY: 'MEDELLIN - AUTOPISTA SUR',
    CDA: 'AJUSTEV SAS',
    RED: 'AJUSTEV',
    TIPO_DE_VEHICULO: 'PESADOS',
    DIRECCION: 'CARRERA 52 N°31-73 AUTOPISTA SUR FRENTE AL PUENTE GUAYAQUIL',
    LUNES_A_VIERNES: '8:00 AM - 5:00 PM',
    SABADOS: '8:00 AM - 12:00 PM'
  },
  {
    UUID: '504',
    CITY: 'MEDELLIN - MOVICENTRO',
    CDA: 'AJUSTEV SAS',
    RED: 'AJUSTEV',
    TIPO_DE_VEHICULO: 'LIVIANOS - MOTOS',
    DIRECCION: 'CENTRO COMERCIAL AUTOMOTRIZ MOVICENTRO LOCAL 30',
    LUNES_A_VIERNES: '8:00 AM - 5:00 PM',
    SABADOS: '8:00 AM - 12:00 PM'
  },
  {
    UUID: '505',
    CITY: 'BOGOTA - CC CARRERA',
    CDA: 'AJUSTEV SAS',
    RED: 'AJUSTEV',
    TIPO_DE_VEHICULO: 'LIVIANOS - MOTOS',
    DIRECCION: 'AV AMERICAS N° 50-15 LOCAL D 248 CENTRO COMERCIAL CARRERA',
    LUNES_A_VIERNES: '8:00 AM - 5:00 PM',
    SABADOS: '8:00 AM - 12:00 PM'
  },
  {
    UUID: '506',
    CITY: 'BOGOTA - KENWORTH',
    CDA: 'AJUSTEV SAS',
    RED: 'AJUSTEV',
    TIPO_DE_VEHICULO: 'LIVIANOS - MOTOS - PESADOS',
    DIRECCION: 'CARRERA 126ª N° 17-50  CASA INGLESA KENWORTH DE LA MONTOÑA',
    LUNES_A_VIERNES: '8:00 AM - 5:00 PM',
    SABADOS: '8:00 AM - 12:00 PM'
  },
  {
    UUID: '507',
    CITY: 'BOGOTA - COLINA',
    CDA: 'AJUSTEV SAS',
    RED: 'AJUSTEV',
    TIPO_DE_VEHICULO: 'LIVIANOS - MOTOS - PESADOS',
    DIRECCION:
      'CALLE 147 N° 72-35 COLINA CAMPESTRE SENTIDO NORTE SUR SOBRE LA BOYACA',
    LUNES_A_VIERNES: '8:00 AM - 5:00 PM',
    SABADOS: '8:00 AM - 12:00 PM'
  },
  {
    UUID: '508',
    CITY: 'BOGOTA - BIMA',
    CDA: 'CDA METROPOLITANO BIMA',
    RED: 'CINEL',
    TIPO_DE_VEHICULO: 'LIVIANOS - MOTOS - PESADOS',
    DIRECCION:
      'AUTOPISTA NORTE N° 232 - 435 EXTERIOR 1 CDA METROPOLITANO CENTRO COMERCIAL BIMA',
    LUNES_A_VIERNES: '8:00 AM - 5:00 PM',
    SABADOS: '8:00 AM - 12:00 PM'
  },
  {
    UUID: '509',
    CITY: 'CALI - CC CARRERA',
    CDA: 'AJUSTEV SAS',
    RED: 'AJUSTEV',
    TIPO_DE_VEHICULO: 'LIVIANOS - MOTOS - PESADOS',
    DIRECCION: 'Calle 52 N° 1B - 160 CENTRO COMERCIAL CARRERA',
    LUNES_A_VIERNES: '8:00 AM - 5:00 PM',
    SABADOS: '8:00 AM - 12:00 PM'
  }
];

export const ZONE_CDA = [
  { CODE: '201', DESCRIPTION: 'NORTE' },
  { CODE: '202', DESCRIPTION: 'SUR' },
  { CODE: '203', DESCRIPTION: 'ORIENTE' },
  { CODE: '204', DESCRIPTION: 'OCCIDENTE' }
];
