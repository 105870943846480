import React, { useEffect } from 'react';
import PlateRestriccionDesktop from './plate-restriccion-desktop/PlateRestriccionDesktop';
import repairAuto from '../../../images/errors/repair-auto.svg';
import paso from '../../../images/errors/plate-restriction/contacto-mesa-de-trabajo.svg';
import Btn from '../../../shared/components/button/generic-button/Generic-button';
import './PlateRestriccion.scss';

import { useHistory } from 'react-router-dom';
import { analyticsService } from '../../../services/analyticsService/analytics.service';

const PlateRestriccion = () => {
  const history = useHistory();

  const dataActionAnalyticsCallExpert = {
    eventProps: {
      category: 'emision',
      action: 'inspeccion a domicilio - placa y cc',
      label: 'hablar con un experto',
      type_flow: 'transversal'
    }
  };

  const dataActionAnalyticsFinish = {
    eventProps: {
      category: 'emision',
      action: 'inspeccion a domicilio - placa y cc',
      label: 'finalizar',
      type_flow: 'transversal'
    }
  };

  useEffect(() => {
    const dataPageAnalytics = {
      title: 'inspeccion-domicilio-restrinccion-placa-y-cc - emision',
      UrlVp: history?.location?.pathname
    };
    analyticsService('page', dataPageAnalytics);
  }, []);

  return (
    <>
      <PlateRestriccionDesktop>
        <div className="container plate-restriccion">
          <div className="title">
            <div className="title__img">
              <img src={repairAuto} alt="Repair auto" />
            </div>
            <h1 className="title__text">Liberty Autos</h1>
          </div>
          <section className="section">
            <div className="section__text">
              <p>
                <b>En este momento no podemos ayudarte por este canal</b>
              </p>
              <p>contáctanos para continuar con el proceso</p>
            </div>
            <div className="section__img">
              <img src={paso} alt="Pass" />
            </div>
            <div className="section__btn">
              <div className="line-black">&nbsp;</div>
              <div
                className="section__btn-expert"
                onClick={() =>
                  analyticsService('event', dataActionAnalyticsCallExpert)
                }
              >
                <Btn>
                  <p>Hablar con un experto</p>
                </Btn>
              </div>
              <div
                className="section__btn-finish"
                onClick={async() => {
                  await analyticsService('event', dataActionAnalyticsFinish);
                  window.location.href = 'https://www.libertycolombia.com.co/';
                }}
              >
                <Btn variant="disabled">
                  <p>Finalizar</p>
                </Btn>
              </div>
            </div>
          </section>
        </div>
      </PlateRestriccionDesktop>
    </>
  );
};

export default PlateRestriccion;
