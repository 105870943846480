import React from 'react';

import './InspectionScheduleListDesktop.scss';

const InspectionScheduleListDesktop = props => {
  return (
    <>
      <div className="inspection-schedule-list-container-desktop">
        {props.children}
      </div>
    </>
  );
};

export default InspectionScheduleListDesktop;
