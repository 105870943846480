import Axios from 'axios';
import {
  ACCESS_TOKEN_SUFFIX,
  authenticateApplication,
  tokenExpired
} from './Oauth.service';

var Promise = require('es6-promise').Promise;

/* istanbul ignore next */
export const getAccessToken = async appName => {
  const APPLICATION_NAME = appName;
  const ACCESS_TOKEN_KEY = `${APPLICATION_NAME}${ACCESS_TOKEN_SUFFIX}`;

  let applicationToken = sessionStorage.getItem(ACCESS_TOKEN_KEY);

  if (tokenExpired(APPLICATION_NAME) || !applicationToken) {
    applicationToken = await authenticateApplication(APPLICATION_NAME);
  }

  return applicationToken;
};

/* istanbul ignore next */
export const api = async appName => {
  const accessToken = await getAccessToken(appName);
  const axiosInstance = Axios.create();

  const requestInterceptor = axiosInstance.interceptors.request.use(
    config => {
      config.headers['Authorization'] = accessToken;

      return config;
    },
    error => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    response => {
      Axios.interceptors.request.eject(requestInterceptor);

      return response;
    },
    error => Promise.reject(error)
  );

  return axiosInstance;
};
