import Axios from 'axios';

const { REACT_APP_TOOLS_WS } = process.env;

export const findCityByName = cityName => {
  return Axios.get(`${REACT_APP_TOOLS_WS}/cities`, {
    params: {
      cityName: cityName.toUpperCase()
    }
  });
};
