import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useHistory } from 'react-router-dom';

import './InspectionScheduledViewMap.scss';

import InspectionSchedulemMap from '../inspection-schedule-map/InspectionScheduleMap';

import exitIcon from '../../../images/detail-popup/close.svg';
import locationPinOutline from '../../../images/LocationPinOutline.svg';
import locationPinOutline2 from '../../../images/LocationPinOutline2.svg';
import callOutline from '../../../images/CallOutline.svg';
import calendar from '../../../images/Calendar.svg';

import { analyticsService } from '../../../services/analyticsService/analytics.service';

const InspectionScheduledViewMap = () => {
  const history = useHistory();

  const location = useLocation();
  const cdaInfo = location.state.params;

  const { name, address, phoneNumber, businessDays, businessHours } = cdaInfo;
  /* istanbul ignore next */
  const goBack = () => {
    history.goBack();
  };

  const dataActionAnalyticsCallConfirm = {
    eventProps: {
      category: 'emision',
      action: 'inspeccion cda - index mapa',
      label: 'confirmar',
      type_flow: 'lineal'
    }
  };

  const dataActionAnalyticsGoNow = {
    eventProps: {
      category: 'emision',
      action: 'inspeccion cda - index mapa',
      label: 'ir ahora',
      type_flow: 'lineal'
    }
  };

  return (
    <>
      <Helmet>
        <title>Inspección en centros de diagnóstico - Liberty Seguros</title>
      </Helmet>
      <div className="inspection-schedule-map">
        <div className="close-inspection-view-map-info" onClick={goBack}>
          <img src={exitIcon} alt="close" />
        </div>
        <div className="pin-number-list">
          <img
            className="pin-image-workshop"
            src={locationPinOutline}
            alt="Not found"
          />
          <h1 className="title-list-cda">{name}</h1>
        </div>
        <div className="wrapper-info-inspection-show-list">
          <div className="container-list-result">
            <img
              className="image-list-icon"
              src={locationPinOutline2}
              alt="Not found"
            />
            <p className="text-list-info"> {address} </p>
          </div>
          <div className="container-subresult">
            <img
              className="image-list-icon"
              src={callOutline}
              alt="Not found"
            />
            <p className="text-list-info"> {phoneNumber} </p>
          </div>
          <div className="container-subresult">
            <img
              className="image-list-icon calendar"
              src={calendar}
              alt="Not found"
            />
            <p className="atention-time-list-title">Horario de atención</p>
            <p className="atention-days-hour-info-list"> {businessDays} </p>
            <p className="atention-days-hour-info-list"> {businessHours} </p>
          </div>
          <div className="confirm-cda-workshop">
            <Link to={'/inspeccion-agendada-confirmada'}>
              <button
                className="button-fixed-clear"
                onClick={() =>
                  analyticsService('event', dataActionAnalyticsCallConfirm)
                }
              >
                Confirmar
              </button>
            </Link>
          </div>
          <div className="confirm-cda-workshop">
            <Link to={'/inspeccion-agendada-confirmada'}>
              <button
                className="button-fixed-clear-outliner"
                onClick={() =>
                  analyticsService('event', dataActionAnalyticsGoNow)
                }
              >
                Ir ahora
              </button>
            </Link>
          </div>
        </div>
      </div>
      <InspectionSchedulemMap cdaList={[cdaInfo]} />
    </>
  );
};

export default InspectionScheduledViewMap;
