/* istanbul ignore file */
import { inspectionApi } from './InspectionsApi.service';

const { REACT_APP_INSPECTIONS_API } = process.env;

export const findInspectionById = async id => {
  return await (await inspectionApi())?.get(
    `${REACT_APP_INSPECTIONS_API}/inspection/${id}`
  );
};
