import React from 'react';

import './NotFound.scss';

const NotFound = () => {
  return (
    <>
      <div className="page-not-found">Resource not found</div>
    </>
  );
};

export default NotFound;
