import React from 'react';

import './BaseButton.scss';

const BaseButton = ({ className, textButton, type }) => {
  return (
    <>
      <div className="base-button-component twelve columns">
        <button type={type} className={className}>
          {textButton}
        </button>
      </div>
    </>
  );
};

export default BaseButton;
