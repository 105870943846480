import React from 'react';
import { configure } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';

import './DocumentTypeSelect.scss';

import { DOCUMENT_TYPE_IDENTITY } from '../../../constants/Constants';

import arrowDownSelect from '../../../../images/arrowDownSelect.svg';

configure({ adapter: new Adapter() });

const DocumentTypeSelect = ({
  value: inputValue,
  name: inputName,
  onChange
}) => {
  return (
    <>
      <div className="document-type-select u-full-width">
        <div className="content-select">
          <select onChange={onChange} value={inputValue} name={inputName}>
            <option value="">Tipo de documento</option>
            {DOCUMENT_TYPE_IDENTITY.map(documentType => (
              <option key={documentType.code} value={documentType.code}>
                {documentType.description}
              </option>
            ))}
          </select>
          <i className="arrow-down-select-icon">
            <img src={arrowDownSelect} alt="Not found" />
          </i>
        </div>
      </div>
    </>
  );
};

export default DocumentTypeSelect;
