import React from 'react';

import './ConfirmInspectionDesktop.scss';

const ConfirmInspectionDesktop = props => {
  return (
    <>
      <div className="container-schedule-insp-home-desktop">
        <div className="wrapper">
          <div className="image-background-desktop" />
          <div className="card-inspection-home">{props.children}</div>
        </div>
      </div>
    </>
  );
};

export default ConfirmInspectionDesktop;
