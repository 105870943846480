import React from 'react';
import BaseButton from '../base-button/BaseButton';

import './EventButton.scss';

const EventButton = ({ textButton, type }) => {
  return (
    <>
      <BaseButton
        type={type}
        className="event-button-component u-full-width"
        textButton={textButton}
      />
    </>
  );
};

export default EventButton;
