import React from 'react';

import './HeaderDesktop.scss';

import callMainDesktop from '../../../../images/Group-7.svg';

const HeaderDesktop = props => {
  return (
    <>
      <div className="header-desktop-container">
        {props.children}
        <div className="container-call-image-desktop">
          <a href="tel:0313077050">
            <img src={callMainDesktop} alt="no-found" />
            <p>Bogotá: 3077050</p>
          </a>
        </div>
      </div>
    </>
  );
};

export default HeaderDesktop;
