/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import React, { useEffect, useRef, useState } from 'react';

import pin from '../../../images/LocationPinOutline2.svg';
import markerPin from '../../../images/LocationPinOutline.svg';
import pin1 from '../../../images/pin1.svg';
import pin2 from '../../../images/pin2.svg';
import pin3 from '../../../images/pin3.svg';
import pin4 from '../../../images/pin4.svg';
import pin5 from '../../../images/pin5.svg';
import pin6 from '../../../images/pin6.svg';
import calendar from '../../../images/Calendar.svg';
import call from '../../../images/CallOutline.svg';
import car from '../../../images/Group12.svg';

const LoadMap = ({ cdaList }) => {
  const googleMapRef = useRef(null);
  let googleMap = null;
  const pins = [pin1, pin2, pin3, pin4, pin5, pin6];

  const iconList = {
    icon1: 'https://i.postimg.cc/qM1cjVmt/Location-Pin-Outline.png'
  };

  const [markers, setMarkers] = useState([]);
  const [positions, setPositions] = useState([]);

  const popUP = `<div>
                    <img style="float: left;" src=${car} alt="Not found" />
                    <h1 style="font-size: 1.2em; font-weight: bold;">@name</h1>
                    <div style="margin-top: 2rem;">
                      <img
                        style="float: left; margin-right: 1rem;"
                        src=${pin}
                        alt="Not found"
                      />
                      <p style="font-size: 14px;">@address</p>
                    </div>
                    <div style="margin-top: 1rem;">
                      <img
                        style="float: left; margin-right: 1rem;"
                        src=${call}
                        alt="Not found"
                      />
                      <p style="font-size: 14px;">@phone</p>
                    </div>
                    <div style="margin-top: 1rem;">
                      <img style="float: left; margin-right: 1rem; calendar" src=${calendar} alt="Not found" />
                      <p style="font-size: 14px;">Horario de atención</p>
                      <p style="font-size: 14px;">@days</p>
                      <p style="font-size: 14px;">@hours</p>
                    </div>
                    <div style="margin-top: 2rem;
                    background-color: #28A3AF;
                    padding-top: 1rem;
                    text-align: center;
                    padding-bottom: 1rem;
                    color: white;
                    font-size: 0.9rem;
                    font-weight: bold;
                    border-radius: 4px;">
                    ¿Cómo llegar?
                    </div>                 
                </div>`;

  /* istanbul ignore next */
  useEffect(() => {
    const getLatLng = async cdasListArray => {
      const geoCoder = new window.google.maps.Geocoder();
      const locationPromises = cdasListArray.map(
        cda =>
          new Promise((resolve, reject) => {
            geoCoder.geocode({ address: cda.address }, (results, status) => {
              if (status === 'OK' && results[0].geometry) {
                resolve({
                  lat: results[0].geometry.location.lat(),
                  lng: results[0].geometry.location.lng(),
                  cdaId: cda.id
                });
              } else {
                resolve({ lat: 0, lng: 0 });
              }
            });
          })
      );
      await Promise.all(locationPromises).then(positions =>
        setPositions(positions.filter(position => position.lat !== 0))
      );
    };
    getLatLng(cdaList);
  }, []);

  /* istanbul ignore next */
  useEffect(() => {
    googleMap = initGoogleMap();
    const bounds = new window.google.maps.LatLngBounds();
    setMarkers(
      positions.map((position, index) => {
        const marker = createMarker(position, index);

        bounds.extend(marker.position);

        return marker;
      })
    );

    googleMap.fitBounds(bounds);
  }, [positions]);

  /* istanbul ignore next */
  useEffect(() => {
    if (markers && markers.length > 0) {
      markers.map(marker => {
        const cdaId = positions.find(
          position => position.lat === marker.position.lat()
        ).cdaId;
        const cda = cdaList.find(cda => cda.id === cdaId);
        const contentString = popUP
          .replace('@name', cda.name)
          .replace('@address', cda.address)
          .replace('@phone', cda.phoneNumber)
          .replace('@days', cda.businessDays)
          .replace('@hours', cda.businessHours);
        const infoWindow = new window.google.maps.InfoWindow({
          content: contentString
        });

        marker.addListener('click', function() {
          infoWindow.open(googleMap, marker);
        });
      });
    }
  }, [markers]);
  /* istanbul ignore next */
  const initGoogleMap = () => {
    return new window.google.maps.Map(googleMapRef.current, {
      center: { lat: 4.6897002, lng: -74.1639319 },
      zoom: 10
    });
  };
  /* istanbul ignore next */
  const createMarker = (markerObj, index) =>
    new window.google.maps.Marker({
      position: { lat: markerObj.lat, lng: markerObj.lng },
      map: googleMap,
      icon: {
        url: pins[index],
        scaledSize: new window.google.maps.Size(50, 50)
      }
    });

  return (
    <div
      ref={googleMapRef}
      style={{ height: `60vh`, margin: `1em 0`, borderRadius: `0.5em` }}
    />
  );
};

export default LoadMap;
