import React from 'react';

import './InspectionScheduledConfirmDesktop.scss';

const InspectionScheduledConfirmDesktop = props => {
  return (
    <>
      <div className="container-inspection-scheduled-confirm-desktop">
        <div className="wrapper">
          <div className="image-background-desktop" />
          <div className="card-inspection-home">{props.children}</div>
        </div>
      </div>
    </>
  );
};

export default InspectionScheduledConfirmDesktop;
