import React, { useState, useEffect } from 'react';
import LoadMap from './LoadMap';

const GOOGLE_MAP_API_KEY = 'AIzaSyBtDlmJVZeKt9O4evTyMnLKfktjZnMlB6E';
/* istanbul ignore next */
const loadGoogleMapScript = callback => {
  if (
    typeof window.google === 'object' &&
    typeof window.google.maps === 'object'
  ) {
    callback();
  } else {
    const googleMapScript = document.createElement('script');
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}`;
    window.document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener('load', callback);
  }
};
/* istanbul ignore next */
const InspectionSchedulemMap = ({ cdaList }) => {
  const [loadMap, setLoadMap] = useState(false);

  useEffect(() => {
    loadGoogleMapScript(() => {
      if (cdaList.length) {
        setLoadMap(true);
      }
    });
  });

  return (
    <div className="row">
      {!loadMap ? <div /> : <LoadMap cdaList={cdaList} />}
    </div>
  );
};

export default InspectionSchedulemMap;
