import React from 'react';
import { configure } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';

import './DropArrow.scss';

import carLogo from '../../../images/Carro.svg';

configure({ adapter: new Adapter() });

const DropArrow = ({ brand, vehicleClass, model, plate }) => {
  return (
    <>
      <div className="drop-arrow-component arrow-bottom">
        <p className="vechicle-data">
          <span>
            <img className="drop-arrow-image" src={carLogo} alt="no found" />
          </span>{' '}
          {brand} {vehicleClass} {model} - {plate}
        </p>
      </div>
    </>
  );
};

export default DropArrow;
