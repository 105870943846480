import React, { useEffect } from 'react';
import SummaryDealDesktop from './summary-deal-desktop/SummaryDealDesktop';
import repairAuto from '../../../images/errors/repair-auto.svg';
import paso from '../../../images/errors/summary-deal/paso.svg';
import Btn from '../../../shared/components/button/generic-button/Generic-button';
import { Helmet } from 'react-helmet';

import { useHistory } from 'react-router-dom';
import { analyticsService } from '../../../services/analyticsService/analytics.service';

import './SummaryDeal.scss';

const Summary = () => {
  const history = useHistory();

  const dataActionAnalyticsCallExpert = {
    eventProps: {
      category: 'emision',
      action: 'inspeccion a domicilio - vigente',
      label: 'hablar con un experto',
      type_flow: 'transversal'
    }
  };

  const dataActionAnalyticsFinish = {
    eventProps: {
      category: 'emision',
      action: 'inspeccion a domicilio - vigente',
      label: 'finalizar',
      type_flow: 'transversal'
    }
  };

  useEffect(() => {
    const dataPageAnalytics = {
      title: 'inspeccion-domicilio-vigente - emision',
      UrlVp: history?.location?.pathname
    };
    analyticsService('page', dataPageAnalytics);
  }, []);

  return (
    <>
      <Helmet>
        <title>Inspección a domicilio - Liberty Seguros</title>
      </Helmet>
      <SummaryDealDesktop>
        <div className="container summary-component">
          <div className="title">
            <div className="title__img">
              <img src={repairAuto} alt="Repair auto" />
            </div>
            <h1 className="title__text">Liberty Autos</h1>
          </div>
          <section className="section">
            <div className="section__text">
              <h2>
                <b>Gracias por</b> preferirnos
              </h2>
              <p>Ya cuentas con una orden de inspección vigente</p>
            </div>
            <div className="section__img">
              <img src={paso} alt="Pass" />
              <p className="section__img-text">
                Contáctanos para recibir una mejor asesoría.
              </p>
            </div>
            <div className="section__btn">
              <div className="line-black">&nbsp;</div>
              <div
                className="section__btn-expert"
                onClick={() =>
                  analyticsService('event', dataActionAnalyticsCallExpert)
                }
              >
                <Btn>
                  <p>Hablar con un experto</p>
                </Btn>
              </div>
              <div
                className="section__btn-finish"
                onClick={async() => {
                  await analyticsService('event', dataActionAnalyticsFinish);
                  window.location.href = 'https://www.libertycolombia.com.co/';
                }}
              >
                <Btn variant="disabled">
                  <p>Finalizar</p>
                </Btn>
              </div>
            </div>
          </section>
        </div>
      </SummaryDealDesktop>
    </>
  );
};

export default Summary;
