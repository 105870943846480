import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import './InspectionScheduleSelectedDesktop.scss';

import { findInspectionById } from '../../../../services/Inspections.service';

import repairAuto from '../../../../images/errors/repair-auto.svg';

const InspectionScheduleSelectedDesktop = props => {
  const { id } = useParams();
  const [inspectionId, setInspectionId] = useState(id);
  const [brand, setBrand] = useState('');
  const [vehicleClass, setVehicleClass] = useState('');
  const [model, setModel] = useState('');
  const [plate, setPlate] = useState('');

  /* istanbul ignore next */
  useEffect(() => {
    if (inspectionId) {
      findInspectionById(inspectionId)
        .then(response => {
          const inspection = response.data;

          if (inspection.id) {
            setInspectionId(inspection.id);
          }

          if (inspection.brand) {
            setBrand(inspection.brand);
          }

          if (inspection.class) {
            setVehicleClass(inspection.class);
          }

          if (inspection.model) {
            setModel(inspection.model);
          }

          if (inspection.plate) {
            setPlate(inspection.plate);
          }
        })
        .catch(error => {
          return;
        });
    }
  }, []);

  return (
    <>
      <div className="container-schedule-insp-selected-desktop">
        <div className="wrapper">
          <div className="image-background-desktop" />
          <div className="card-inspection-home">
            <h1 className="title-search-inspection-desktop">
              Centros de Diagnóstico
            </h1>
            <h3 className="subtitle-search-inspection-desktop">
              Automotor CDA
            </h3>
            <p className="text-info-search-inspection-desktop">
              Selecciona la ciudad en la que deseas hacer la inspección de tu
              vehículo
            </p>
            <div className="table-description-vehicle">
              <table className="table-info-vehicle">
                <tr>
                  <td>
                    <img src={repairAuto} alt="No Found" />
                  </td>
                  <td>
                    <h3 className="technical-data-sheet">Marca</h3>
                  </td>
                  <td>
                    <h3 className="technical-data-sheet">Linea</h3>
                  </td>
                  <td>
                    <h3 className="technical-data-sheet">Modelo</h3>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h3 className="info-plate"> {plate} </h3>
                  </td>
                  <td>
                    <p className="technical-vehicle-data"> {brand} </p>
                  </td>
                  <td>
                    <p className="technical-vehicle-data"> {vehicleClass} </p>
                  </td>
                  <td>
                    <p className="technical-vehicle-data"> {model} </p>
                  </td>
                </tr>
              </table>
            </div>
            {props.children}
          </div>
        </div>
      </div>
    </>
  );
};

export default InspectionScheduleSelectedDesktop;
