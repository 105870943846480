import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';

import './InspectionScheduleSelected.scss';

import CitiesSelect from '../../../shared/components/select/cities-select/CitiesSelect';
import InspectionShceduleList from '../inspection-schedule-list/InspectionScheduleList';
import InspectionScheduleSelectedDesktop from './inspection-schedule-selected-desktop/InspectionScheduleSelectedDesktop';
import InspectionScheduleMap from '../inspection-schedule-map/InspectionScheduleMap';
import DropArrow from '../../../shared/components/drop-arrow/DropArrow';

import { findInspectionCDAs } from '../../../services/InspectionsCDAs.service';
import { findInspectionById } from '../../../services/Inspections.service';

import listOutline from '../../../images/ListOutline.svg';
import listOutlineHover from '../../../images/inspection-desktop/inspection-selected-home-desktop/ListOutlineHover.svg';
import mapSolid from '../../../images/MapSolid.svg';
import mapSolidHover from '../../../images/inspection-desktop/inspection-selected-home-desktop/MapOutlineHover.svg';

import { useHistory } from 'react-router-dom';
import { analyticsService } from '../../../services/analyticsService/analytics.service';

const ScheduledHomeInspectionSelected = () => {
  const [cityCode, setCityCode] = useState('');
  const [stateCode, setStateCode] = useState('');

  const [active, setActive] = useState(false);
  const [showResult, setShowResult] = useState('');

  const [cdaList, setCdaList] = useState([]);
  const { id } = useParams();

  const [brand, setBrand] = useState('');
  const [vehicleClass, setVehicleClass] = useState('');
  const [model, setModel] = useState('');
  const [plate, setPlate] = useState('');
  const history = useHistory();
  const [refresh, setrefresh] = useState(true);

  useEffect(() => {
    if ((cityCode, stateCode)) {
      setrefresh(false);
      const stateCodeCovert = stateCode.replace(/^0+/, '');
      const cityCodeCovert = cityCode.toString().replace(/^0+/, '');
      findInspectionCDAs(cityCodeCovert, stateCodeCovert).then(response => {
        const { data } = response;
        setrefresh(true);
        setCdaList(data);
      });
    }
  }, [cityCode, stateCode]);

  /* istanbul ignore next */
  useEffect(() => {
    if (id) {
      findInspectionById(id)
        .then(response => {
          if (response.status === 200) {
            const inspection = response.data;

            if (inspection.brand) {
              setBrand(inspection.brand);
            }

            if (inspection.class) {
              setVehicleClass(inspection.class);
            }

            if (inspection.model) {
              setModel(inspection.model);
            }

            if (inspection.plate) {
              setPlate(inspection.plate);
            }
          }
        })
        .catch(error => {
          return;
        });
    }
  }, [id]);

  const setActiveShow = show => {
    if (show === 'list') {
      setActive(true);
      setShowResult('list');
    } else {
      setActive(false);
      setShowResult('map');
    }
  };

  let dataActionAnalyticsList = {
    eventProps: {
      category: 'emision',
      action: 'inspeccion cda - index lista',
      label: 'listado',
      type_flow: 'lineal'
    }
  };

  useEffect(() => {
    const dataPageAnalytics = {
      title: 'inspeccion-centro-de-diagnostico-index-lista - emision',
      UrlVp: history?.location?.pathname
    };
    analyticsService('page', dataPageAnalytics);
  }, []);

  return (
    <>
      <Helmet>
        <title>Inspección en centros de diagnóstico - Liberty Seguros</title>
      </Helmet>
      <InspectionScheduleSelectedDesktop>
        <div className="drop-arrow-scheduled-home-inspection-selected">
          <DropArrow brand={brand} vehicleClass={vehicleClass} model={model} plate={plate} />
        </div>
        <div className="container-schedule-inspection-selected">
          <div className="row-margin-container">
            <h1 className="title-search-inspection">Centros de Diagnóstico</h1>
            <h3 className="subtitle-search-inspection">Automotor CDA</h3>
            <p className="text-info-search-inspection">
              Selecciona la ciudad en la que deseas hacer la inspección de tu vehículo
            </p>
            <div className="base-selected-schedule">
              <CitiesSelect
                className="twelve columns"
                placeholder="Ciudad"
                value={cityCode}
                onChange={city => {
                  setCityCode(city.cityCode);
                  setStateCode(city.stateCode);
                }}
              />
              <input type="hidden" name="cityCode" value={cityCode} />
              <input type="hidden" name="stateCode" value={stateCode} />
            </div>
            <div className="result-search-cda">
              Hemos encontrado <span> {cdaList.length} registros</span> para la búsqueda realizada
            </div>
            <div className="button-map-list">
              <button
                onClick={() => {
                  setActiveShow('list');
                  dataActionAnalyticsList.eventProps.label = 'listado';
                  analyticsService('event', dataActionAnalyticsList);
                }}
                className={`button-list ${active ? 'active' : null}`}
              >
                <img className="imgage-map-list" src={active ? listOutlineHover : listOutline} alt="Not found" />
                Listado
              </button>
              <button
                onClick={() => {
                  setActiveShow('map');
                  dataActionAnalyticsList.eventProps.label = 'mapa';
                  analyticsService('event', dataActionAnalyticsList);
                }}
                className={`button-map-show ${!active ? 'active' : null}`}
              >
                <img className="imgage-map-list" src={active ? mapSolid : mapSolidHover} alt="Not found" />
                Mapa
              </button>
            </div>
            <div>
              {refresh &&
                (showResult === 'list' ? (
                  <InspectionShceduleList
                    inspectionId={id}
                    cdaList={cdaList}
                    cityCode={cityCode.toString().replace(/^0+/, '')}
                    stateCode={stateCode.replace(/^0+/, '')}
                  />
                ) : (
                  <InspectionScheduleMap cdaList={cdaList} />
                ))}
            </div>
          </div>
        </div>
      </InspectionScheduleSelectedDesktop>
    </>
  );
};

export default ScheduledHomeInspectionSelected;
