/* istanbul ignore file */
import React from 'react';
import { useHistory } from 'react-router-dom';

import './InspectionScheduleList.scss';

import InspectionScheduleListDesktop from './inspection-schedule-list-desktop/InspectionScheduleListDesktop';
import Button from '../../../shared/components/button/generic-button/Generic-button';
import { sendCdasInfo } from '../../../services/sendCdasInfo.service';
import ROUTES from '../../../shared/constants/Routes';

import locationPinOutline2 from '../../../images/LocationPinOutline2.svg';
import pin1 from '../../../images/pin1.svg';
import pin2 from '../../../images/pin2.svg';
import pin3 from '../../../images/pin3.svg';
import pin4 from '../../../images/pin4.svg';
import pin5 from '../../../images/pin5.svg';
import pin6 from '../../../images/pin6.svg';
import callOutline from '../../../images/CallOutline.svg';
import calendar from '../../../images/Calendar.svg';
import locationPinOutline1 from '../../../images/LocationPinOutline1.svg';
import group5 from '../../../images/Group5.svg';
import { analyticsService } from '../../../services/analyticsService/analytics.service';

const InspectionScheduleList = ({ cdaList, inspectionId, cityCode, stateCode }) => {
  let history = useHistory();
  const pinImages = [pin1, pin2, pin3, pin4, pin5, pin6];

  const dataActionAnalyticsConfirm = {
    eventProps: {
      category: 'emision',
      action: 'inspeccion cda - index lista',
      label: 'confirmar',
      type_flow: 'lineal'
    }
  };

  const handleClick = async cda => {
    await sendCdasInfo(inspectionId, cda.id, stateCode, cityCode)
      .then(response => {
        const { data } = response;
        if (data.status === 200) {
          return history.push(ROUTES.INSPECTIONS.MECHANICAL_WORKSHOP.CONFIRM);
        }
      })
      .catch(() => {
        return history.push(ROUTES.INSPECTIONS.ERRORS.ERROR_SERVICE);
      });
    analyticsService('event', dataActionAnalyticsConfirm);
  };

  const showCdaSelected = cda => {
    history.push(ROUTES.INSPECTIONS.MECHANICAL_WORKSHOP.SHOW_INFO_CDA, {
      params: cda
    });
  };

  return (
    <>
      <InspectionScheduleListDesktop>
        {cdaList.map((cda, index) => (
          <div key={cda.id} className="inspection-schedule-list-container">
            <div className="pin-number-list">
              <img className="pin-image-workshop" src={pinImages[index]} alt="Not found" />
              <h1 className="title-list-cda">{cda.name}</h1>
            </div>
            <div className="wrapper-info-inspection-show-list">
              <div className="container-list-result">
                <img className="image-list-icon" src={locationPinOutline2} alt="Not found" />
                <p className="text-list-info"> {cda.address} </p>
              </div>
              <div className="container-subresult">
                <img className="image-list-icon" src={callOutline} alt="Not found" />
                <p className="text-list-info"> {cda.phoneNumber} </p>
              </div>
              <div className="container-subresult">
                <img className="image-list-icon calendar" src={calendar} alt="Not found" />
                <p className="atention-time-list-title">Horario de atención</p>
                <p className="atention-days-hour-info-list"> {cda.businessDays} </p>
                <p className="atention-days-hour-info-list"> {cda.businessHours} </p>
              </div>
              <div className="container-subresult link-info">
                <img className="image-list-icon-color" src={group5} alt="Not found" />
                <p className="text-info-list-view-more">Menos información</p>
              </div>
              <div className="container-subresult letter-color" onClick={showCdaSelected.bind(this, cda)}>
                <img className="image-list-icon-color" src={locationPinOutline1} alt="Not found" />
                Ver mapa
              </div>
            </div>
            <div className="confirm-cda-workshop">
              <Button className="btn" style={{ fontSize: '19px' }} onClick={handleClick.bind(this, cda)}>
                {'Confirmar'}
              </Button>
            </div>
          </div>
        ))}
      </InspectionScheduleListDesktop>
    </>
  );
};

export default InspectionScheduleList;
