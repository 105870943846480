import React from 'react';

import './PlateRestriccionDesktop.scss';

const PlateRestriccionDesktop = props => {
  return (
    <>
      <div className="container-plate-restriccion-desktop">
        <div className="wrapper">
          <div className="image-background-desktop" />
          <div className="card-inspection-home">{props.children}</div>
        </div>
      </div>
    </>
  );
};

export default PlateRestriccionDesktop;
