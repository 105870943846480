import ClientOAuth2 from 'client-oauth2';

import { capitalize } from '../shared/helpers/string.helper';

/* istanbul ignore next */
const missingEnvVarError = envVarName => {
  throw new Error(`Missing ${envVarName} environment variable`);
};

export const ACCESS_TOKEN_SUFFIX = 'AccessToken';
export const ACCESS_TOKEN_EXPIRE_SUFFIX = `${ACCESS_TOKEN_SUFFIX}Expire`;

/* istanbul ignore next */
export const authenticateApplication = async applicationName => {
  const envApplicationName = applicationName.toUpperCase();

  const authUrlEnvVarName = `REACT_APP_${envApplicationName}_AUTH_URL`;
  const clientIdEnvVarName = `REACT_APP_${envApplicationName}_CLIENT_ID`;
  const clientSecretEnvVarName = `REACT_APP_${envApplicationName}_CLIENT_SECRET`;
  const scopeEnvVarName = `REACT_APP_${envApplicationName}_SCOPE`;

  const accessTokenUri = process.env[authUrlEnvVarName] || '';

  if (!accessTokenUri) missingEnvVarError(authUrlEnvVarName);

  const clientId = process.env[clientIdEnvVarName] || '';

  if (!clientId) missingEnvVarError(clientIdEnvVarName);

  const clientSecret = process.env[clientSecretEnvVarName] || '';

  if (!clientSecret) missingEnvVarError(clientSecretEnvVarName);

  const scope = process.env[scopeEnvVarName] || '';

  const oauth2Client = new ClientOAuth2({
    clientId,
    clientSecret,
    accessTokenUri,
    scopes: [scope]
  });

  const clientCredentials = await oauth2Client.credentials.getToken();

  const { accessToken, tokenType } = clientCredentials;

  const completeAccessToken = `${capitalize(tokenType)} ${accessToken}`;
  const expirationDate = new Date(Date.now() + +clientCredentials.data.expires_in * 1000).getTime().toString();

  sessionStorage.setItem(`${applicationName}${ACCESS_TOKEN_SUFFIX}`, completeAccessToken);
  sessionStorage.setItem(`${applicationName}${ACCESS_TOKEN_EXPIRE_SUFFIX}`, expirationDate);

  return completeAccessToken;
};

export const tokenExpired = applicationName => {
  const expirationDate = sessionStorage.getItem(`${applicationName}${ACCESS_TOKEN_EXPIRE_SUFFIX}`);

  if (!expirationDate) return false;

  return +expirationDate < Date.now();
};
