/* istanbul ignore file */
import { inspectionApi } from './InspectionsApi.service';

const { REACT_APP_INSPECTIONS_API } = process.env;

export const findInspectionCDAs = async(stateCode, cityCode) => {
  return await (await inspectionApi())?.get(
    `${REACT_APP_INSPECTIONS_API}/cda?stateCode=${cityCode}&cityCode=${stateCode}`
  );
};
