import React from 'react';
import { configure } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';

import './Header.scss';

import HeaderDesktop from './header-desktop/HeaderDesktop';

import logo from '../../../images/logo.svg';
import callMain from '../../../images/Group-7.svg';

configure({ adapter: new Adapter() });

const Header = () => {
  return (
    <>
      <HeaderDesktop>
        <header className="header-component u-full-width">
          <div className="header-icon">
            <img src={logo} alt="no-found" />
          </div>
          <div className="vertical-line" />
          <p className="text-title">Inspecciones Liberty Autos</p>
          <div className="container-call-image">
            <a href="tel:0313077050">
              <img src={callMain} alt="no-found" />
            </a>
          </div>
        </header>
      </HeaderDesktop>
    </>
  );
};

export default Header;
