import React from 'react';
import { configure } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';

import './ContactUs.scss';

import monitored from '../../../images/Vigilado.svg';

configure({ adapter: new Adapter() });

const ContactUs = () => {
  return (
    <div className="contact-us-component">
      <div className="contact-us-img">
        <img src={monitored} alt="Not Found" />
      </div>
      <div className="contact-us-div">
        <h5 className="contact-us-h3">
          Asistencias{' '}
          <span>
            <a href="#!">#224</a>
          </span>
        </h5>
      </div>
      <div className="contact-us-div">
        <h5 className="contact-us-h3">Lineas de atencion</h5>
        <p className="contact-us-p">
          Bogota:{' '}
          <span>
            <a href="#!">(57 1)3077050</a>
          </span>
        </p>

        <p className="contact-us-p">
          Resto del pais:{' '}
          <span>
            <a href="#!">01 8000 113390</a>
          </span>
        </p>
      </div>
    </div>
  );
};

export default ContactUs;
