import React from 'react';
import { configure } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';

import './Footer.scss';

import sFacebook from '../../../images/SFacebook.svg';
import sLinkedIn from '../../../images/SLinkedIn.svg';
import sTwitter from '../../../images/STwitter.svg';
import sYouTube from '../../../images/SYouTube.svg';
import sInstagram from '../../../images/SInstagram.svg';
import phone from '../../../images/phone.svg';

configure({ adapter: new Adapter() });

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className="footer-component">
        <p className="footer-p">Siguenos en:</p>
        <div className="footer-center">
          <div className="icons-social-red">
            <img src={sFacebook} alt="no found" />
          </div>
          <div className="icons-social-red">
            <img src={sLinkedIn} alt="no found" />
          </div>
          <div className="icons-social-red">
            <img src={sTwitter} alt="no found" />
          </div>
          <div className="icons-social-red">
            <img src={sYouTube} alt="no found" />
          </div>
        </div>
        <div className="footer-div">
          <p className="footer-p">Copyright &copy; 2018 Liberty Seguros</p>
          <a className="footer-a" href="#!">
            Terminos de uso y Privacidad
          </a>
        </div>
      </div>
      <footer className="desktop-footer desktop-footer--primary">
        <div className="desktop-footer--primary-container">
          <div className="first-line">
            <div className="contact">
              <p>Líneas de atención</p>
              <img src={phone} alt="Phone" />
              <p>
                <span>Bogotá:</span> (57 1) 3077050
              </p>
              <p>
                <span>Resto del País:</span> 01 8000 113390
              </p>
            </div>
            <div className="social">
              <p>Siguenos en:</p>
              <a
                href="https://www.facebook.com/LibertySegurosCO/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={sFacebook} alt="facebook" />
              </a>
              <a
                href="https://twitter.com/LibertySegCol"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={sTwitter} alt="twitter" />
              </a>
              <a
                href="https://www.linkedin.com/company/liberty-seguros-s-a-colombia"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={sLinkedIn} alt="linkedin" />
              </a>
              <a
                href="https://instagram.com/libertyseguroscol?igshid=1cn0stf5xtyit"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={sInstagram} alt="instagram" />
              </a>
              <a
                href="https://www.youtube.com/user/LibertySegurosCO"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={sYouTube} alt="youtube" />
              </a>
            </div>
          </div>
          <div className="second-line">
            <div className="copyright">
              <p>Copyright © {currentYear} Liberty Seguros</p>
            </div>
            <div className="terms">
              <p>Términos de uso y Privacidad</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
