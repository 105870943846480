/* eslint-disable max-len */

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './App.scss';

import ROUTES from './shared/constants/Routes';
import Header from './shared/components/header/Header';
import ContactUs from './shared/components/contact-us/ContactUs';
import Footer from './shared/components/footer/Footer';

import ScheduledHomeInspection from './pages/scheduled-home-inspection/ScheduledHomeInspection';
import ConfirmInspection from './pages/confirm-inspection/ConfirmInspection';
import ScheduledHomeInspectionSelected from './pages/inspection-schedule-cda/inspection-schedule-selected/ScheduledHomeInspectionSelected';
import InspectionScheduledViewMap from './pages/inspection-schedule-cda/inspection-scheduled-view-map/InspectionScheduledViewMap';
import NotFound from './pages/not-found/NotFound';
import ErrorService from './pages/errors/error-service/ErrorService';
import PlateRestriccion from './pages/errors/plate-restriccion/PlateRestriccion';
import SummaryDeal from './pages/errors/summary-deal/SummaryDeal';
import InspectionScheduleConfirm from './pages/inspection-schedule-cda/inspection-scheduled-confirm/InspectionScheduledConfirm';

function App() {
  return (
    <>
      <Header />

      <Router>
        <Switch>
          <Route exact path="/" component={ScheduledHomeInspection} />

          <Route exact path={ROUTES.NOT_FOUND} component={NotFound} />

          <Route
            exact
            path={ROUTES.INSPECTIONS.HOME.INSPECT}
            component={ScheduledHomeInspection}
          />

          <Route
            exact
            path={ROUTES.INSPECTIONS.HOME.CONFIRM}
            component={ConfirmInspection}
          />

          <Route
            exact
            path={ROUTES.INSPECTIONS.MECHANICAL_WORKSHOP.INSPECT}
            component={ScheduledHomeInspectionSelected}
          />

          <Route
            exact
            path={ROUTES.INSPECTIONS.MECHANICAL_WORKSHOP.CONFIRM}
            component={InspectionScheduleConfirm}
          />

          <Route
            exact
            path={ROUTES.INSPECTIONS.ERRORS.ERROR_SERVICE}
            component={ErrorService}
          />

          <Route
            exact
            path={ROUTES.INSPECTIONS.ERRORS.PLATE_RESTRICCION}
            component={PlateRestriccion}
          />

          <Route
            exact
            path={ROUTES.INSPECTIONS.ERRORS.SUMMARY_DEAL}
            component={SummaryDeal}
          />

          <Route
            exact
            path={ROUTES.INSPECTIONS.MECHANICAL_WORKSHOP.SHOW_INFO_CDA}
            component={InspectionScheduledViewMap}
          />
        </Switch>
      </Router>

      <ContactUs />
      <Footer />
    </>
  );
}

export default App;
