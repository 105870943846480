const ROUTES = {
  NOT_FOUND: '/no-encontrado',
  INSPECTIONS: {
    HOME: {
      INSPECT: '/autos/domicilios/:id',
      CONFIRM: '/inspeccion-agendada-home'
    },
    MECHANICAL_WORKSHOP: {
      INSPECT: '/autos/talleres/:id',
      CONFIRM: '/inspeccion-agendada-confirmada',
      SHOW_INFO_CDA: '/informacion-cda'
    },
    ERRORS: {
      ERROR_SERVICE: '/error-servicio',
      SUMMARY_DEAL: '/resumen-domicilio',
      PLATE_RESTRICCION: '/restriccion-placa'
    }
  }
};

export default ROUTES;
