import React from 'react';

import './Generic-button.scss';

const GenericButton = ({ variant = 'default', children, ...atributes }) => {
  return (
    <button {...atributes} className={`btn btn--${variant}`}>
      {children}
    </button>
  );
};

export default GenericButton;
