import TagManager from "react-gtm-module";

const pageEventAnalytics = (data) => {
  const { title, UrlVp } = data;
  TagManager.dataLayer({
    dataLayer: {
      event: 'ga_event_virtualPage',
      title, UrlVp
    }
  });
};

const actionEventAnalytics = (data) => {
  const { eventProps } = data;
  const { action, category, label, userId, typeFlow } = eventProps;
  TagManager.dataLayer({
    dataLayer: {
      event: 'ga_event',
      eventProps: {
        action,
        category,
        label,
        'user_id': userId,
        'tipo flujo': typeFlow
      }
    }
  });
};

export const analyticsService = (type, data) => {
  switch (type) {
  case 'page':
    pageEventAnalytics(data);
    break;
  case 'event':
    actionEventAnalytics(data);
    break;
  }
};
