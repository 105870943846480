const maskEmail = email => {
  return email.replaceAll(
    /(?<=.)[^@](?=[^@]*?[^@]@)|(?:(?<=@.)|(?!^)\\G(?=[^@]*$)).(?=.*[^@]\\.)/g,
    '*'
  );
};

const maskPhone = phone => {
  return phone.replaceAll(/(?!^.?).(?!.{0}$)/g, '*');
};

const maskId = id => {
  return id.replaceAll(/(?!^.?).(?!.{0}$)/g, '*');
};

export { maskEmail, maskPhone, maskId };
